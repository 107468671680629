@import "scss/base.scss";

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.pageBlock {
    page-break-after: always;
}

.detailsHeader {
    margin-left: $margin-small;
    display: flex;
    div.unitCount {
        margin-right: $margin-small;
    }
}

.editCast {
    margin-bottom: $margin-large;
    display: flex;
}

.editButton {
    margin-left: $margin-small;
}

.castTitle {
    text-decoration:underline;
    font-weight: $font-weight-heavy;
    font-size: $font-size-medium;

}

.groupName {
    // text-orientation:sideways;
    writing-mode: vertical-rl;
    //text-orientation: upright;
    padding-top: $padding-small;
    border-style: solid;
    margin-top: $margin-small;
    margin-bottom: $margin-small;
    height: 100px;
}

.showDisplayArchetypeMember {
    padding: $padding-small;
}

.deleteModal {
    background-color: $color-background;
    border-color: $color-primary;
    border-style: $border-style;
    margin: $margin-small;
    padding: $padding-small;
}

.archetypeMembersWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
}

.archetypeEntryCard {
    
    //margin: $margin-small;
    max-width: 330px;
    min-width: 320px;
    position:relative;
    margin-bottom: $margin-large;
}

.modButtons {
    position:absolute;
    right: 0;
    bottom: $margin-large*2;
    .viewArchetypeButton {
        right: 0;
        margin-left: $margin-small;
        min-width: 40px;
        background-color: $color-background;
    }
}

.castMember {
    page-break-inside: avoid;
}

.memberWrapper {
    display: flex;
}

.tacticsBlock {
    display: flex;
    flex-wrap: wrap;

}

.tacticHeader {
    font-size: $font-size-medium;
    margin-right: $margin-small;
}

.tacticBlock {
    margin: $margin-small;
    display: flex;
}

.traitsList {
    @include tablet-up {
        column-count: 3;

    }

    @media print {
        column-count: 3;
    }

}

.tagBlock {
   page-break-inside: avoid; 
}


.tagTitle {
    margin-bottom: $margin-small;
    margin-top: $margin-small;
}

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.tagWrapper {
    padding: $padding-small;
    margin: $margin-small;

    margin-top: none;
    background-color: $color-background;
    color: $color-primary;
}

.firstTagWrapper {
   page-break-inside: avoid; 
}
