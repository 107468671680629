@import "scss/base.scss";

.counter {
    width: $font-size;
    height: $font-size;
    padding: $padding-small;
    border-radius: 50%;
    background-color: $color-background-secondary;
    color: $color-secondary;
    text-align: center;
    right: -1 * ($font-size + $padding-small);
    bottom: -1 * ($font-size + $padding-small);
    position: absolute;
}