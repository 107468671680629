//https://davidwalsh.name/write-media-queries-sass
$tablet-width: 769px;
$desktop-width: 1024px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin corner-cut {
  mask-image: url('../../assets/svgs/corner-cut.svg'), linear-gradient(#fff 0 0);;
  -webkit-mask-image: url('../../assets/svgs/corner-cut.svg'), linear-gradient(#fff 0 0);
  
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  
  -webkit-mask-composite: xor;
  mask-composite: exclude;

  -webkit-mask-position-x: right, center;
  -webkit-mask-position-y: bottom, center;

  mask-size: 12px, 100%;
  -webkit-mask-size: 12px, 100%;

  background-color: red;
}