@import "scss/base.scss";

.deleted {
    color: $color-text-disabled;
}

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.editButtons {
    a {
        margin-right: $margin-small;
    }
    
    margin-bottom: $margin-medium;
}

.deleteModal {
    background-color: $color-background;
    border-color: $color-primary;
    border-style: $border-style;
    margin: $margin-small;
    padding: $padding-small;

    .deleteModalButtons {
        margin-top: $margin-small;

        .editButton {
            margin-right: $margin-small;
        }
    
    }
}

