@import "scss/base.scss";

.editContainer {
    display: flex;
}

.container {
    margin: $margin-small;
    padding: $padding-small;
    border-style: solid;
    border-width: $border-size;
}

.archetypeEntryCard {
    background-color: $color-background;
    border-color: $color-primary;
    border-style: $border-style;
      margin: $margin-small;
      padding: $padding-small;
}

.archetypeTitle {
    display: flex;
    
    .name {

    }

    .standing {

        right: 0;
        margin-left: auto;
    }

    .viewArchetypeButton {
        right: 0;
        margin-left: $margin-small;
   //     padding-left:0;
    }

    .viewArchetypeCloseButton {
        border-radius: $radius-small;
        margin-left: $margin-small;
        right: 0;
    }
}

.updateButtons {
    right: 0;
    margin-left: auto;
}

.statline {
    display: flex;
}

.statlineEntry {
    margin-left: $margin-small; 
}

.selectedTagOptionButton {
    background-color: $color-background-secondary;
    color: $color-button-text;

    &:hover {
        background-color: rgba($color-background-secondary, 0.75);
        color: $color-button-text;;
    }
}

.tagOptionButton {
    margin-right: $margin-small;
}

.labels {
 //   min-width: 33%;
}

.entries {
    .field {
        display: flex;
        margin-bottom: $margin-very-small;
    }

    .textArea {
       width: 300px;
       min-height:150px; 
    }

    .labels {
        width: 100px;
        right: 0;
        text-align: end;
        padding-right: $padding-small;
    }
}
