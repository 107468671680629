@import "scss/base.scss";

.labels {
   min-width: 5rem;
   width: 10rem;
   display: inline-block;
}

.field {
    margin-right: $margin-small;
}

.submitError {
    color: $color-alert;
}