@import "scss/base.scss";


.actionsWrapper {

    margin-top: $margin-medium;
    margin-bottom: $margin-medium;
}

.actions {
    padding: $padding-medium;
}

.archetypeWrapper {
    display: flex;
}

.archetypeSize {
    height: 30px;
    width: 30px;
}

.archetypeBack {
    background-color: $color-background-header;
    color: $color-primary;
}

.archetypeLink {
    font-size: $font-size-large;
    padding: $padding-small;
    a, a:visited { 
        text-decoration: none;
        font-size: $font-size-title;
        font-weight: $font-weight-medium;
        color: $color-title;
    }
}

$corner-height: 10px;

.childWrapper {
    position:relative;
}

.archetypeWrapper {
    position: absolute;
    border-top-style: solid;
    border-left-style: solid;
    border-width: $border-width-medium;
    border-color: $color-border;
    margin: $margin-medium;
}

.archetypeEntryWrapper {
    margin-bottom: $margin-medium;
}
