@import 'scss/base.scss';


.bodyWrapper {
    background-color: $color-layout-background;
    min-height: 100vh;
    display:flex;
    flex-direction:column;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $color-layout-background-dark;
    color: $color-layout-dark;
}