@import "scss/base.scss";

.modal {
    position: relative;
    display: block;
    margin: auto;
    background-color: black;
    min-width:640px;
}

.modalBackground {
    background-color: blue;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 50%;
}

.modalWrapper {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow-y: scroll;
}
