@import 'scss/base.scss';

.header {
    background-color: $color-printable-background;
    color: $color-printable;
    display: flex; 
    align-items: center;
    border-bottom-style: solid;
    border-bottom-color: $color-border;
    @include mobile {
        align-items:start;
        flex-direction:column;
        
    }

    &.headerShowNav {
        margin-left:auto;
        display: block;
        position: fixed;
        right: 0; 
        z-index:100;
        width:100%;
    }
}

.headerTitle {
    align-items: center;
    display:flex;
}

.headerLogo {
    height: 3rem;

    :global(.leaf) {
        fill: white;
    }
}

.loginNav {
   
    display: inline-flex;
    align-items: center;
    margin-top: $margin-small;
    margin-bottom: $margin-small;
    margin-left: auto;
    margin-right: 0;
    right: 0;
    justify-content: flex-end;

    @include mobile {
        display: flex;
    }
}


.name a, .name a:visited, .name {
    color: $color-secondary;
    margin-left: $margin-small;
    margin-right: $margin-small;
}

.nav {
    display:block;

    @include mobile {
        display: none;
      //display: inline-block;
      &.showNav {
        display:flex;
        flex-direction: column;
      }
    }

    margin-left: auto;
    margin-right: $margin-medium;
    right: 0;

    a {
        margin-left: $margin-medium;
    }
}

.hamburgerWrapper {
    display: none;
    @include mobile {
        margin-left:auto;
        margin-right: $margin-small; 
        display: block;
        position: fixed;
        right: 0;
        margin-top: 1rem;
        top: 0;
    }
      //display: inline-block;
}

.hamburger {
    display: inline-block;
    width: $font-size;
    height: $font-size;
}