@import "scss/base.scss";

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.editButtons {
    a, .editButton {
        margin-right: $margin-very-small;
    }
}

.archetypeMembersWrapper {
 display: flex;
 width: 100%;
 flex-wrap: wrap;

 @include mobile {
    display: block;
 }
}

.showArchetypeMember {
    padding: $padding-small;
}

.controlButtonsWrapper {
    padding-top: $padding-medium;
    padding-bottom: $padding-medium;
    margin-bottom: $margin-medium;
}

.traitsList {
    background: $color-background-header;
    min-height: 300px;
    min-width: 300px;
    padding: $padding-small;

}


.mobileHide {
    @include mobile {
        display: none;
    }
}

.mobileSelectFooter {
    display: none;
    @include mobile {
        position:fixed;
        bottom:0;
        display: flex;
        align-items: center;
        margin-bottom: $padding-small;
        gap: $padding-small;
        z-index: 100;
    }

    .mobileSelectButton {
       flex-grow:1; 

       &.mobileHighlight {
        background-color: $color-background-tertiary;
       }
    }

    &.mobileHide {
        @include mobile {
            display: none;
        }
    }

    .mobilePointsBackground {
        background-color: $color-background;
    }

    .mobilePoints {
        color: $color-primary;
        padding: $margin-medium;
    }
}