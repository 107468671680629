@import "scss/base.scss";

.editContainer {
    display: flex;
}

.displayMembers {

}

.container {
    margin: $margin-small;
    padding: $padding-small;
    border-style: solid;
    border-width: $border-size;
}

.swipeableWrapper {
    position: relative;
    margin: 0;
    padding: 0;
}

.swipeableHandlerWrapper {
    
}

.swipeableStartPoint {
    position: absolute;
}

.swipeableDontAllowSelect {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.dragging {
}

.transition {
    // transition: all 1s;
}

.counter {
    width: $font-size;
    height: $font-size;
    padding: $padding-small;
    border-radius: 50%;
    background-color: $color-background-secondary;
    color: $color-secondary;
    text-align: center;
    right: -1 * ($font-size + $padding-small);
    bottom: -1 * ($font-size + $padding-small);
    position: absolute;
}