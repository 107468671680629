@import 'scss/base.scss';

.footer {
    margin-top: $margin-small;
    background-color: $color-background-footer;
    display: flex; 
    align-items: center;
    padding: $padding-large;
    bottom:0;
    margin-top:auto;
    color: $color-text;

    .footerText {
        padding-left: $padding-small;
        padding-right: $padding-small;
    }
}
