@import "scss/base.scss";

.tagTitle {
    font-size: $font-size-medium;
    padding-bottom: $padding-small;
}

.tagDescription {
    position: absolute;
    border: solid;
    border-width: $border-radius;
    padding: $padding-small;
    background: $color-background;
    color: $color-primary;
    z-index: 100;
 }

.editContainer {
    display: flex;
}

.archetypeEntryCard {
    background-color: $color-background;
    // border-color: $border-color;
    border-color: $color-border; //border-color;
    color: $color-primary;
    border-style: $border-style;
    margin: $margin-small;
    padding: $padding-small;
    cursor: pointer;
}

.archetypeTitle {
    display: flex;
    width: 100%;
    
    .name {

    }



    .viewArchetypeButton {
        right: 0;
        margin-left: auto;
        
    }
}

