@import "scss/base.scss";

.userGrid {
  max-width:600px;
  display: grid;
  grid-template-columns: 100px 1fr;

}

.label {
  text-align: right;
  padding-right: $padding-medium;
}

.field {
  text-align: left;
}