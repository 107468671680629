@import "scss/base.scss";


.label {
 margin-right: $margin-medium;
} 

.required, .alert {
  color: $color-alert;   
}

.field {
  border: $border-size solid $color-border;
  box-sizing: content-box;
}

// .select {
//   color: $color-primary;
// }