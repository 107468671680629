@import "scss/base.scss";

.tagTitle {

}

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.tagWrapper {
    padding: $padding-small;
    margin: $margin-small;
    background-color: $color-background;
    color: $color-primary;
}
