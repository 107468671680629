@import "scss/base.scss";

.modal {
    background: $color-background;
    color: $color-primary;
    padding: $padding-small;
}

.loaderField {
    width: calc(100% - $padding-small); 
    min-height: 300px;
}

.loaderButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .editButton {
        display:inline;
        margin-top: $margin-small;
    }
}

.helpImage {
    max-width: 100%;
}

.closeModal {
    top: 0;
    right: 0;
    margin-left: auto;
    margin-top: $margin-small;
    margin-right: $margin-small;
    position:absolute;

}

.error {
    color: $color-alert;
}