@import "scss/base.scss";

.labels {
   width: 5rem;
   display: inline-block;
}

.field {
    margin-right: $margin-small;
}

.submitError {
    color: $color-alert;
}

.googleOAuthButton {
    max-width: 16rem;
    margin-top: $margin-small;
    margin-bottom: $margin-small;
}

.loginLinks {
    margin-top: $margin-small;
}