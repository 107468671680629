@import "scss/base.scss";

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.dataText {
    width: 500px;
    height: 500px;
}

.hidden {
    display:none
}

.entry {
    display: flex;
    margin: $margin-small;
}

.label {
    margin-top: 0;
    margin-bottom: auto;
    min-width: 100px;
}

.dataBlock {
    min-height:500px;
//    width:600px;
}

.dataBlockField {
    width: 100%;
}


.textError {
    border-color: red;
}