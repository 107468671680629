@import "scss/base.scss";

.none {
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: rgba($color-text, 0.75);
  }
}

.button {
  border-radius: $radius;
  cursor: pointer;
  font-size: $font-size;
  padding: $padding-small $padding-medium;
  border-width: $border-width;
  border-style: solid;
}

  :disabled {
    cursor: not-allowed;
  }

  .primary {
    background-color: $color-button-background;
    border-color: $color-button-background;
    color: $color-button-text;

    &:hover {
      background-color: rgba($color-button-background, 0.75);
      border-color: rgba($color-button-background, 0.75);
    }

    &:disabled {
      background-color: rgba($color-button-background, 0.5);
      border-color: rgba($color-button-background, 0.5);

      &:hover {
        background-color: rgba($color-button-background, 0.25);
        border-color: rgba($color-button-background, 0.25);
      }
    }
  }

  .secondary {
    color: $color-primary;
    background-color: transparent;
    border-color: $color-primary;

    &:hover {
      color: $color-button-text;
      background-color: rgba($color-button-background, 0.5);
      border-color: rgba($color-button-background, 0.5);
    }

    &:disabled {
      background-color: rgba($color-button-background-disabled, 0.5);
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
  }


.inline {
  color: $color-primary;
  background-color: transparent;
  border-color: $color-primary;
  padding-top: initial;
  padding-bottom: initial;
  padding-left: $padding-small;
  padding-right: $padding-small;
  height: $line-height-small;
  border-radius: $radius-small;

  &.button {
  }

  &:hover {
    color: $color-button-text;
    background-color: rgba($color-button-background, 0.5);
    border-color: rgba($color-button-background, 0.5);
  }

  &:disabled {
    background-color: rgba($color-button-background-disabled, 0.5);
    border-color: rgba($color-button-background-disabled, 0.5);
    &:hover {
      background-color: rgba($color-button-background-disabled, 0.25);
      border-color: rgba($color-button-background-disabled, 0.25);
    }
  }
}

.linkButton {
  text-decoration-line: none;
}

a.linkButton, a.linkButton:visited {
  color: $color-secondary;
}