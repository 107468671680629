@import "scss/base.scss";

.editContainer {
    display: flex;
}

.actionButtons {
    right: 0;
    
    @include mobile {
        margin-left: auto;
    }
}

.container {
    margin: $margin-small;
    padding: $padding-small;
    border-style: solid;
    border-width: $border-size;
}

.showDisplayArchetypeMember {
    display: flex;
    @include mobile {
        align-items: center;
    }
}
.showDisplayArchetypeMemberInnerWrapper {
    @include mobile {
        margin-left: auto;
        margin-right: auto;
    }
}

.archetypeEntryCard {
    background-color: $color-background;
    border-color: $color-primary;
    border-style: $border-style;

    margin: $margin-small;
    padding: $padding-small;

    @include mobile {
        display: flex;
        flex-direction: column;
    }
}

.showDisplayArchetypeMember {
    padding: $padding-small;
}

.archetypeTitle {
    display: flex;
    flex-flow: row-reverse; 
    .name {
        font-size: $font-size-large;
    }

    .standing {

        right: 0;
        margin-left: auto;
        
        @include mobile {
            left: 0;
            margin-left: 0;
            margin-top:auto;
            margin-bottom: auto;
        }
    }

    .viewArchetypeButton {
        right: 0;
        margin-left: $margin-small;
    }

    .viewArchetypeCloseButton {
        right: 0;
        margin-left: $margin-small;
        border-radius: $radius-small;
    }

    @include mobile {
        flex-direction: column;
        flex-flow:column;
    }
}

.fieldWrapper {
    display: flex;
    margin-bottom: $margin-very-small;
    @include mobile {
        margin-top: $margin-small;
        margin-right: $margin-small;
    }
}

.textArea {
   width: 300px;
   min-height:150px; 
}

.label {
  //  width: 100px;
    left: 0;
    //right: 0;
    //text-align: end;
    padding-right: $padding-small;
    //padding-left: $padding-medium;
    width: 50px;
    @include mobile {
        flex-grow:0;
    }
}

.field {
    min-width:250px;

    @include mobile {
        flex-grow:4;
        min-width: 0;
    }

}


.statline {
    display: flex;
}

.statlineEntry {
    margin-left: $margin-small; 
}

.selectedTagOptionButton {
    background-color: $color-background-secondary;
    color: $color-button-text;

    &:hover {
        background-color: rgba($color-background-secondary, 0.75);
        color: $color-button-text;;
    }
}

.tagOptionButton {
    margin-right: $margin-small;
    margin: $margin-very-small;
    padding: $padding-small;
    border-style: solid;
}

.displayTagVal {
    display: flex;

    .textDescription {
        margin-left: $margin-small;
    }
}