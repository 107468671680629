@import "scss/base.scss";

.actions {
    margin-top: $margin-medium;
    margin-bottom: $margin-medium;
}

.deleted a {
    color: $color-text-disabled;
}
