@import "scss/base.scss";

.modal {
    position: relative;
    display: block;
    margin: auto;
    background-color: $color-background;
    color: $color-primary;
    min-width:640px;
    padding: $margin-small;

    @include mobile {
        width: 100%;
        min-width: auto;
    }
}

.modalToastClass {
    position: relative;
    display: block;
    margin: auto;
    min-width:240px;
}

.modalBackground {
    background-color: $color-background-secondary;
    color: $color-primary;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 50%;
}

.modalWrapper {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow-y: scroll;
}

.modalToastWrapper {
    position: fixed;
    display: flex;
    align-items: left;
    min-width:240px;
    margin: $margin-small;
    right: 0;
    bottom: 0;
    overflow-y: hidden; 
}

.closeModal {
    top: 0;
    right: 0;
    margin-left: auto;
    margin-top: $margin-medium;
    margin-right: $margin-medium;
    position:absolute;

}