@import "scss/base.scss";

.labels {
   min-width: 5rem;
   width: 10rem;
   display: inline-block;
}

.field {
    margin-right: $margin-small;
}

.submitError {
    color: $color-alert;
}
.buttons {
    
    align-items: center;
    text-align: center;
}

.button {
    margin-left: $margin-small;
    margin-right: $margin-small;
}

.confirmModal {
    align-items: center;
    text-align: center;
    @include mobile {
        align-items: left;
        text-align: left;
    }
}

.userInfo {
    margin-left: auto;
    margin-right: auto;
    @include tablet { 
        grid-template-columns: 1fr 1fr;
    }
    @include desktop { 
        grid-template-columns: 1fr 1fr;
    } 
}