@import "scss/base.scss";

.centre {
    text-align: center;
    
    .logo {
        margin-left: auto;
        margin-right: auto;
        height: 300px;
        display: block;

    }
    div {
        margin-left: auto;
        margin-right: auto;
        display: block; 
    }
}
