@import "scss/base.scss";

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.updateButtons {
    margin-left: $padding-small;
}

.entries {
    a {
        color: $color-text-link;
    }
}

.sectionCastsOfRenown {
    margin-top: $margin-very-large;
    cursor: pointer;
    h3 {
        margin-bottom: $margin-very-small;
    }
}