/*
Try to utilize variables as much as possible.  It's one of the best things about a CSS preprocessor like Sass and it makes updates much easier
*/

//theme defaults
$color-primary: #383838;
$color-layout-dark: #C8D8D8;
$color-printable: #000000;
$color-layout-dark-grayed-out: #A8B8B8;
$color-title: #2e577f;
$color-chosen: #3e777f;
$color-secondary: #f3dde1;
$color-camo: #686868;
$color-alert: #ff0000;
$color-success: #b1df95;

// #f92945

//  colour background
$color-background: #ffffff;
$color-layout-background: #d5ddf3;
$color-printable-background: #ffffff;
$color-layout-background-dark: #252d33;
//$color-background: #ffffff;
$color-background-secondary: #2e577f;
$color-background-tertiary: #1e273f;
$color-background-tertiary-grayed-out: #5e677f;
$color-box-shadow: #888888;
$color-background-header: #B1C1CA;
$color-background-footer: #030202;

// colour text
$color-text: #787878;
$color-text-secondary: #ffffff;
$color-text-link: #944552;
$color-text-visited: #2e577f;
$color-text-disabled: #787878;
$color-text-unimportant: #5F5F5F;


// buttons
$color-button-background: $color-background-secondary;
$color-button-text: $color-secondary;

$color-button-background-disabled: #c7c7c7;

// borders:
// $color-border: $color-primary;
$color-border: #2e577f;

// modals
$color-modal-background: #d48e6a;
$color-modal-text: #ffffff;

// header
$color-header-border: #e86d82;
$color-menu-text: #2e577f;
$color-menu-background: #ffffff;

//flashtext
$color-flash-from: #d48e6a;
