@import "scss/base.scss";

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.editButtons {
    a {
        margin-right: $margin-small;
    }
    
    margin-bottom: $margin-medium;
}