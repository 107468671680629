@import "scss/base.scss";

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }

    @include mobile {
        flex-direction: column;
        margin-bottom: $padding-small;   
    }
}

.headerDetails {
    display:flex;
}

.helpButton {
    background-color: $color-background-header;
    border-radius: $radius-large;
    width: $radius;
    height: $radius;
    font-size: $font-size-small;
    color: $color-primary;
    margin-left: $margin-small;
    text-align: center;
    top: 0;
    cursor: pointer;
}

.mobileSelectFooter {
    display: none;
    @include mobile {
        position:fixed;
        bottom:0;
        display: flex;
        align-items: center;
        margin-bottom: $padding-small;
        gap: $padding-small;
        z-index: 100;
    }

    .mobileSelectButton {
       flex-grow:1; 
        background-color: $color-background-tertiary-grayed-out;
        color: $color-layout-dark-grayed-out;
       &.mobileHighlight {
        background-color: $color-background-tertiary;
        color: $color-secondary;
       }
    }

    &.mobileHide {
        @include mobile {
            display: none;
        }
    }

    .mobilePointsBackground {
        background-color: $color-background;
    }

    .mobilePoints {
        color: $color-primary;
        padding: $margin-medium;
    }
}

.editCast {
    display: flex;
    flex-wrap: wrap;
    gap: $padding-small;
    right: 0;
    margin-left: auto;
    @include mobile {
        width: 100%;
    }
}

.mobileHide {
    @include mobile {
        display: none;
    }
}


.editButton {
    margin-left: $margin-very-small;

    @include mobile {
        margin-left: auto;
        align-items: center;
        margin-bottom: $padding-small;
        flex-grow:1;
        text-align:center; 
    }
}

.castHeader {
    display: flex;
    align-items: center;
}

.headerPoints {
    margin-left: $margin-small;
    @include mobile {
        margin-bottom: $padding-small;
    }
}

.castPoints {
    right: 0;
    margin-left: auto;
    margin-right: $margin-small;
}

.editContainer {
    display: flex;
    @include mobile {
        margin-left: auto;
        margin-right: auto;
       flex-direction: column;
    }
}

.displayMembers {

}

.container {
    margin: $margin-small;
    padding: $padding-small;
    border-style: solid;
    border-width: $border-size;
    width: 100%;
    @include mobile {
        margin: 0;
        width:auto;
    }

}

.transition {
    // transition: all 1s;
}

.dragging, .dragged {
    position: relative;
    z-index: 999;
}

.modal {
    position: relative;
    display: block;
    margin: auto;
}

.modalBackground {
    background-color: blue;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 50%;
}

.modalWrapper {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.upOrDownModal {
    display: flex;
    justify-content:center;
}