@import "scss/base.scss";

.editContainer {
    display: flex;
}

.container {
    margin: $margin-small;
    padding: $padding-small;
    border-style: solid;
    border-width: $border-size;
}

.archetypeEntryCard {
        margin: $margin-small;
        margin-bottom: $margin-large;
        //padding: $padding-small;
        @include mobile {
            margin: $margin-small 0;
        }
}

.archetypeTitle {
    display: flex;
    
    .name {

    }

    .standing {

        right: 0;
        margin-left: auto;
    }

    .viewArchetypeButton {
        right: 0;
        margin-left: $margin-small;
    }
}
