@import "scss/base.scss";

.wrapper {

    display: flex;
    flex: 1;
    margin: $margin-small;
}

.jsonEditor {
}

.label {
 margin-right: $margin-medium;

} 

.required, .alert {
  color: $color-alert;   
}

.field {
  border: $border-size solid $color-border;
}
