@import "scss/base.scss";

.editContainer {
    display: flex;
}

.container {
    margin: $margin-small;
    padding: $padding-small;
    border-style: solid;
    border-width: $border-size;
}

.archetypeEntryCard {
    
    margin: $margin-small;
    max-width: 330px;
    min-width: 320px;
    position:relative;
    margin-bottom: $margin-large;

    @include mobile {

    }
}

.archetypeTitle {
    display: flex;
    
    .name {

    }

    .standing {

        right: 0;
        margin-left: auto;
    }
}

.modButtons {
    position:absolute;
    right: 0;
    right: $margin-medium;
    bottom: $margin-large*2;
    display: flex;
    flex-direction: column;


    .viewArchetypeButton {
        right: 0;
        margin-left: $margin-small;
        background-color: $color-background;
        margin-top: $margin-very-small;
    }


    .viewUpDownButton {
        right: 0;
        margin-left: $margin-small;
        background-color: $color-background;
        font-size: $font-size-small;
        padding-left: $padding-very-small;
        padding-right: $padding-very-small;
        margin-top: $margin-very-small;
    }
}