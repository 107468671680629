@import 'scss/base.scss';


.bodyWrapper {
    min-height: 100vh;
    display:flex;
    flex-direction:column;
    overflow-x: hidden;
    overflow-y: auto;
    color: $color-printable;
    background-color: $color-printable-background;

}
@media print {

@page {
    size: 1292px 1672px;
    margin: 0.75in;
}

@page {
    /* margin box at top right showing page number */
    @top-right {
        margin: 1in;
      content: "Page " counter(pageNumber);
    }
  }


}
