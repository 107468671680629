@import "scss/base.scss";

.name {
   font-weight: bold;
}

.archetypeMemberGroup {
   display: flex;
   //padding: $padding-small;
   flex-direction: column;
   width: 300px;
   color: $color-primary;

   &.bookView {
      width: initial;
      flex-direction: initial;
   }
}

.baseWrapper {
   display: flex;
   flex-direction: column;
   border-style: solid;
   border-width: $border-width-medium;
   border-color: $color-border;
   min-height: 130px; 
   background-color: $color-background;

   &.descriptionWrapper {
      min-height: 50px;
   }

   @include mobile {
      width: initial;
   }
}

.entryWrapper {
   margin:0;
   padding: 0;
   display: inline;
   cursor: pointer;
}

.statsBlock {
   display:flex;

   .header {

   }

   .text {

   }
}

.traitsHeader {
   font-weight: $font-weight-medium;
}

.traitsBox {
   padding: $padding-small;
}
.traitsOuterBoxWrapper {
}

.traitsBoxWrapper {
}
   
.backgroundTraitsBoxWrapper {
   background-color: $color-background-header;
}

.backgroundDescriptionBoxWrapper {
   background-color: $color-background;
   color: $color-primary;
}

.traitsOuterBoxShrunk {
   flex-direction: column;
   max-width: initial;
   min-width: initial;
   z-index:0;
   &.bookView {
      flex-direction: initial;
      width:   180px;
      margin-top: $margin-small;
      margin-left: -1*$border-width-medium;
      margin-right:0;
      margin-bottom: $margin-small;
   }
}

.traitsOuterBox {
   flex-direction: column;
   margin-top: -1*$border-width-medium;
   max-width: initial;
   min-width: initial;
   z-index:0;
   &.bookView {
      flex-direction: initial;
      width:   180px;
      margin-top: $margin-small;
      margin-left: -1*$border-width-medium;
      margin-right:0;
      margin-bottom: $margin-small;
   }
}

.statTraitsWrapper {
   font-style: italic;
   .traitEntry {
      display: inline;
   }
}

.skillTraitsWrapper {
   .traitEntry {
      display: inline;
   }

   &.easeRightMargin {
      margin-right: $margin-large;
   }
}

.skillUpgradesLineWrapper {
   padding: $margin-small $margin-small;
   cursor: pointer;
}

.upgradesLine {
   margin: $margin-small;
   border-top-style: solid;
}

.skillUpgradesLine {
   border-top-style:solid;
}

.statsBox {
   display: flex;
   justify-content: space-evenly;
   padding: $padding-small;
}


.statBox {
   flex-grow: 1;

   .header {
      background: $color-background-header;
      padding-left: $padding-small;
   }

   .statVal {
      padding-left: $padding-small;

   }
}

.nameWrapper {
   display: flex;
}

.nameBox {
   background: $color-background-tertiary;
   font-size: $font-size-medium;
   font-weight: $font-weight-medium;
   padding: $padding-small;
   color: $color-secondary;
   width: 100%;
   min-height: 50px;
   display: flex;
   align-items: stretch;

   &.lineStyle {
      background-color: $color-printable-background;
      color: $color-printable;
      border-bottom-style: solid;
      border-right-style: solid;
      border-color: $color-border;
   }

   &.lineStyleTactic {
      background-color: $color-printable-background;
      color: $color-printable;
      border-right-style: solid;
      border-color: $color-border;
   }
}

.expandSpace { 
   flex: 1;
   font-size: $font-size-small;
   text-align: right;

   .expandButton {
      color: $color-camo;
      cursor: pointer;
      display:inline-block;
   }
}

.ptsBox {
   padding: $padding-small;
   padding-left: $padding-small;
   background-color: $color-background;

   .ptsHeader {
      padding-left: $padding-small;
      padding-right: $padding-small;
      font-weight: $font-weight-medium;
   }

   .ptsText {
      padding-left: $padding-small;
      padding-right: $padding-small;
   }
}

.header {
   background: $color-background-header;
   font-weight: $font-weight-medium;
}

.tagDescription {
   position: absolute;
   border: solid;
   border-width: $border-radius;
   padding: $padding-small;
   background: $color-background;
   max-width: 512px;
   z-index: 100;
}

.backgroundTraitsBoxWrapperClosed {
   background-color: $color-background;
}

.ptsBoxClosed {
   padding: $padding-small;
   padding-left: $padding-small;
   background-color: transparent;
   padding-right: calc($padding-small + $border-width-medium);

   .ptsHeaderClosed {
      padding-left: $padding-small;
      padding-right: $padding-small;
      font-weight: $font-weight-medium;
   }

   .ptsText {
      padding-left: $padding-small;
      padding-right: $padding-small;
   }
}