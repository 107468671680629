@import "scss/base.scss";


.traitsList {
    background: $color-background-header;
    color: $color-primary;
    min-height: 300px;
    min-width: 300px;
    padding: $padding-small;
}

.spacing {
    margin-bottom: $margin-small;
    margin-top: $margin-small;
   display:flex; 
}

.tacticHeader {
    padding-right: $margin-small;
    cursor: pointer;
}

.selected {
    color: $color-chosen;
    font-weight: $font-weight-heavy;
    font-size: $font-size-large;
}

.button {
    margin-left: $margin-very-small;
    margin-right: $margin-very-small;

}