@import "./base/colors.scss";
@import "./base/sizes.scss";
@import "./base/grid.scss";
@import "./base/media-queries.scss";

.hide {
  display: none;
}

.greyed-out {
  color: $color-text-disabled !important;
}

$border-style: solid;

a {
  color: $color-text-link;
}

a:visited {
  color: $color-text-visited;
}