@import "scss/base.scss";

.name {
   font-weight: bold;
}

.archetypeMemberGroup {
   border: solid;
   border-width: $border-radius;
   border-width: $border-width-small;
   margin: $margin-small;
   padding: $padding-small;
}

.memberStats {
   display: flex;
   list-style: none;

   li {
      margin-right: $margin-small;
   }
}

.tagDescription {
   position: absolute;
   border: solid;
   border-width: $border-radius;
   padding: $padding-small;
   background: $color-background;
   color: $color-primary;
   max-width: 512px;
}