$padding-very-small: 0.3rem;
$padding-small: 0.5rem;
$padding-medium: 0.75rem;
$padding-large: 1rem;

$radius-small: 0.75rem;
$radius: 1rem;
$radius-large: 3rem;

$margin-very-small: 0.3rem;
$margin-small: 0.5rem;
$margin-medium: 0.75rem;
$margin-large: 1rem;
$margin-very-large: 2rem;

$font-size-small: 0.8rem;
$font-size: 1rem;
$font-size-large: 1.6rem;

$line-height-small: $font-size-small*2;
$line-height: $font-size*2;
$line-large: $font-size-large*2;

$font-size-medium: 1.3rem;
$font-size-title: 2rem;
$font-size-title-medium: 2rem;
$font-size-title-large: 3rem;
$border-size: 1px;

$spacing-small: 0.5rem;
$spacing-medium: 0.75rem;
$spacing-large: 1rem;

$font-weight-medium: 600;
$font-weight-heavy: 900;

$border-radius: 0.2rem;
$border-radius-medium: 0.2rem;
$border-radius-large: 0.5rem;
$border-width: 2px;
$border-width-small: 1px;
$border-width-medium: 2px;