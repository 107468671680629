@import "scss/base.scss";

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.updateButtons {
    margin-left: $padding-small;
}

.field {
    max-width: 50%;
}

.labelHeader {
    width: 100px;
    right: 0;
    text-align: end;
    padding-right: 0.5rem;
    margin-right: 0.75rem;
}

.labels {
    //   min-width: 33%;
   }
   
   .entries {
        .fieldWrapper {
            display: flex;
            margin-bottom: $margin-very-small;

        }

        .archetypeSelect {
            width: 250px;
        }
   
        .field {
          width: 250px;
        }
        
        .textArea {
           width: 250px;
           min-height:150px; 
        }
   
       .labels {
           width: 100px;
           right: 0;
           text-align: end;
           padding-right: $padding-small;
       }
   }
