@import "scss/base.scss";

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }

    @include mobile {
        display: block;
    }
}

.detailsHeader {
    display: flex;
    div.unitCount {
        margin-right: $margin-small;
    }
}

.editCast {
    margin-bottom: $margin-large;
    display: flex;
}
.editButton {
    margin-left: $margin-small;
}

.detailsHeader {
    display: flex;
    div.unitCount {
        margin-right: $margin-small;
    }
}

.castTitle {
    text-decoration:underline;
    font-weight: $font-weight-heavy;
    font-size: $font-size-medium;

}

.showDisplayArchetypeMember {
    padding: $padding-small;
}

.deleteModal {
    background-color: $color-background;
    border-color: $color-primary;
    border-style: $border-style;
    margin: $margin-small;
    padding: $padding-small;
}

.archetypeMembersWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
   
    @include mobile {
       display: block;
    }
}


.mobileHide {
    @include mobile {
        display: none;
    }
}

.mobileSelectFooter {
    display: none;
    @include mobile {
        position:fixed;
        bottom:0;
        display: flex;
        align-items: center;
        margin-bottom: $padding-small;
        gap: $padding-small;
        z-index: 100;
    }

    &.mobileHide {
        @include mobile {
            display: none;
        }
    }

    .mobileSelectButton {
       flex-grow:1; 

       &.mobileHighlight {
        background-color: $color-background-tertiary;
       }
    }

    .mobilePointsBackground {
        background-color: $color-background;
    }

    .mobilePoints {
        color: $color-primary;
        padding: $margin-medium;
    }
}