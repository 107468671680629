@import "scss/base.scss";

.name {
   font-weight: bold;
}


.tagDescription {
   position: absolute;
   border: solid;
   border-width: $border-radius;
   padding: $padding-small;
   background: $color-background;
   max-width: 512px;
   z-index: 100;
}