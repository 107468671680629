@import "scss/base.scss";

.tagTitle {
    font-weight: $font-weight-heavy;
}

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.tagWrapper {

}