@import "scss/base.scss";

.sectionHeader {
    display: flex;
    align-items: center;

    h3 {
        margin-right: $padding-small;
    }
}

.dataText {
    width: 500px;
    height: 500px;
}

.hidden {
    display:none
}

.entry {
    display: flex;
    margin: $margin-small;
    @include mobile {
        flex-direction: column;
    }
}

.label {
    margin-top: 0;
    margin-bottom: auto;
    min-width: 100px;
}

.dataBlock {
    min-height:500px;
    min-width:500px;

    @include mobile {
        min-width: auto;
        flex-direction: column;
    }
}