@import "scss/base.scss";


.label {
 margin-right: $margin-medium;
} 

.required, .alert {
  color: $color-alert;   
}

.field {
  border: $border-size solid $color-border;
  padding: 0;
}

.error {
  margin-left: $margin-small;
}