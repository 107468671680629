@import "scss/base.scss";


.actionsWrapper {

    margin-top: $margin-medium;
    margin-bottom: $margin-medium;
}

.actions {
    padding: $padding-medium;
}


.archetypeWrapper {
   // display: flex;
}

.archetypeSize {
    height: 30px;
    width: 30px;
}

.archetypeLink {
    font-size: $font-size-large;
    // margin: $margin-medium;
    //  border-radius: $border-radius;
    //  border-bottom-right-radius: 10px; // $border-radius-large;
    //  border-width: $border-width-medium;
    //  border-style: solid;
    //  border-color: $color-border;
    // border-corner-shape: bevel;
    padding: $padding-small;
    a { 
        text-decoration: none;
        font-size: $font-size-title;
        font-weight: $font-weight-medium;
    }

      //  background: linear-gradient(135deg, transparent 20px, $color-background-footer 0);
      //  border: 0;
}

$corner-height: 10px;
$corner-height-large: 10px;
$border-width-cut-corner: 2px;
$border-width-cut-corner-thick: 6px;
$border-width-cut-small: $border-width-medium;

.archetypeWrapper {
    position: relative;
    border-top-style: solid;
    border-left-style: solid;
    border-width: $border-width-cut-corner;
    border-color: $color-border;
    //margin: $margin-medium;

    &.cutBorderThick {
        border-width: $border-width-cut-corner-thick;

    } 
}

.archetypeBorderWrapper:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(-45deg);
  -ms-transform: skew(-45deg);
  transform: skew(-45deg);
  border-right-style: solid;
  border-bottom-style: solid;
  
  height: $corner-height;
  width: calc(100% - $corner-height - $border-width-cut-corner);
  border-width: $border-width-cut-corner;
  border-color: $color-border;
}

.archetypeBorderWrapper.cutBorderThick:after {
    width: calc(100% - $corner-height - $border-width-cut-corner-thick);
    border-width: $border-width-cut-corner-thick;
}

.archetypeBorderWrapper {
    pointer-events: none;
}

 .archetypeBorderWrapper:before {
   content: '';
   position: absolute;
   top: 0 - $border-width-cut-corner;
   left: 0;
   width: 100%;
   -webkit-transform-origin: 100% 0;
   -ms-transform-origin: 100% 0;
   transform-origin: 100% 0;
   border-right-style: solid;
    pointer-events: none;

   border-color: $color-border;

   height: calc(100% - $corner-height);
   border-width: $border-width-cut-corner;
   transform: translateX(-1*$border-width-cut-corner); //skew(-45deg);
 
   &.cutBorderThick {
      border-width: $border-width-cut-corner-thick;
      transform: translateX(-1*$border-width-cut-corner-thick); //skew(-45deg);
      height: calc(100% - $corner-height);
  }
 }

 .archetypeBorderWrapper.cutBorderThick:before {
    content: '';
    position: absolute;
    top: 0 - $border-width-cut-corner-thick;
    left: 0;
    width: 100%;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    border-right-style: solid;
     pointer-events: none;
 
    border-color: $color-border;
 
    height: calc(100% - $corner-height);

    border-width: $border-width-cut-corner-thick;

    transform: translateX(-1*$border-width-cut-corner-thick);  
 }

@mixin corner-cut2 {
    mask-image: linear-gradient(#fff 0 0), url('./svgs/corner-cut.svg'), linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    -webkit-mask-image: linear-gradient(#fff 0 0), url('./svgs/corner-cut.svg'), linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    
    -webkit-mask-composite: xor, source-over, source-over, source-over;
    mask-composite: exclude, add, add;
  
    -webkit-mask-position-x: center, right, right, right;
    -webkit-mask-position-y: center, bottom, bottom, bottom;
  
    mask-size: 100%, $corner-height + $border-width-cut-corner*2, $border-width-cut-corner 100%, 100% $border-width-cut-corner;
    -webkit-mask-size: 100%, $corner-height + $border-width-cut-corner*2, $border-width-cut-corner 100%, 100% $border-width-cut-corner;
  
}

@mixin corner-cut2Thick {
    mask-image: linear-gradient(#fff 0 0), url('./svgs/corner-cut.svg') , linear-gradient(#fff 0 0),  linear-gradient(#fff 0 0);
    //-webkit-mask-image: url('./svgs/corner-cut.svg'), linear-gradient(#fff 0 0); //, linear-gradient(#fff 0 0);
    //-webkit-mask-image: linear-gradient(#fff 0 0), url('./svgs/corner-cut.svg'), linear-gradient(#fff 0 0),  linear-gradient(#fff 0 0);
    -webkit-mask-image: linear-gradient(#fff 0 0), url('./svgs/corner-cut.svg'), linear-gradient(#fff 0 0),  linear-gradient(#fff 0 0);
    
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    
    -webkit-mask-composite: xor, source-over, source-over, source-over;
    mask-composite: exclude, add, add;
  
    -webkit-mask-position-x: center, right, right, right;
    -webkit-mask-position-y: center, bottom, bottom, bottom;
  
    mask-size: 100%, $corner-height + $border-width-cut-corner-thick*2, $border-width-cut-corner-thick 100%, 100% $border-width-cut-corner-thick ;
    -webkit-mask-size: 100%, $corner-height + $border-width-cut-corner-thick*2, $border-width-cut-corner-thick 100% , 100% $border-width-cut-corner-thick ;
    //-webkit-mask-size:  100%, $border-width-cut-corner-thick, $border-width-cut-corner-thick 100%;
    
}

.childWrapper {
     width: 100%;
}

.backgroundChildWrapper {
    position:absolute;
    width: 100%;
    height: 100%;
}

.backgroundOuterChildrenWrapper {
    position:absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.innerBackgroundChildreWrapper {
    width: 100%;
    height: 100%;
}

.backgroundChildrenWrapper {
    width: 100%;
    height: 100%;
    @include corner-cut2;
  }
  
  .backgroundChildrenWrapper.cutBorderThick {
    width: 100%;
    height: 100%;
    @include corner-cut2Thick;
  }
  

.childrenWrapper {
  width: 100%;
  @include corner-cut2;
}

.childrenWrapper.cutBorderThick {
  width: 100%;
  @include corner-cut2Thick;
}
