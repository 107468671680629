@import 'scss/base.scss';

.contentWrapper {
    padding: $padding-large;
    overflow: visible;
    background-color: $color-printable-background;
    max-width: 100%;
}

@media print {

}