@import "scss/base.scss";

.actions {
    margin-top: $margin-medium;
    margin-bottom: $margin-medium;
}

.icon {
    height: 30px;
    width: 30px;
}